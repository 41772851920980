<template>
  <custom-button
    target="_blank"
    :class="['flex items-center text-[20px]', buttonClass || 'px-2 md:px-4 py-2']"
    size="none"
    pill
    is-link
    :href="link"
  >
    <nuxt-icon
      name="entities/whats-app"
      class="inline"
    />
    <span
      class="ml-2 font-semibold text-base"
      :class="spanClass || 'hidden md:inline-block'"
    >WhatsApp</span>
  </custom-button>
</template>

<script setup lang="ts">
import { MessengerWhatsapp } from '~/common/types/messengers/Messenger.whatsapp'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  phoneNumber: {
    type: String as PropType<MessengerWhatsapp['phoneNumber']>,
    required: true,
  },
  spanClass: {
    type: [String, Object, Array],
    default: '',
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
  textToSend: {
    type: String,
    default: '',
  },
})

const link = computed(() => {
  if (props.textToSend) {
    return `https://wa.me/${props.phoneNumber}?text=${props.textToSend}`
  }
  return `https://wa.me/${props.phoneNumber}`
})

</script>
